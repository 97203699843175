
.main-content {

    .immo-filter__buttons {
        width: 100%;
        background: white;

        position: fixed;
        bottom: 0;
        right: 0;
        margin: 10px 0 0;
        padding: 10px 10px 0;
        z-index: 9;

        @media @screen-lg-min {
            grid-column: 2 e("/") -1;
            position: static;
            text-align: right;
        }
    }

    .immo-filter--closed .immo-filter__buttons {
        position: static;
    }

    .immo-filter__button--toggler {
        background: @oebb-red;
        color: white;
        padding: 10px 15px;
        margin: 0 0 10px auto;
        text-align: right;
        text-decoration: none;
        border: 1px solid @oebb-red;

        &:hover,
        &:focus {
            background: white;
            color: @oebb-red;
        }
    }

    .immo-filter__button--searchagent,
    .immo-filter__button--reset {
        color: @oebb-red;
        margin: 0;
        background: transparent;
        border: none;
        padding: 5px 15px;

        &:hover,
        &:focus {
            text-decoration: underline;
            color: @oebb-red;
        }
    }

    .immo-filter--closed .immo-filter__button--searchagent,
    .immo-filter--closed .immo-filter__button--reset {
        display: none;
    }

    .searchagent__button-close {
        color: @oebb-red;
        background: transparent;
        border: none;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 40px;
    }

    .immo-filter {
        overflow: hidden;
        margin-bottom: 40px;
        margin-top: 20px;

        @media @screen-lg-min {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    .immo-filter__result-count {
        font-family: @font-light;
        color: @black-70;
    }


    .immo-filter__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .immo-filter__list--object-types {
        @media @screen-sm-min {
            column-count: 2;
            column-gap: 10px;
        }

        @media @screen-lg-min {
            column-count: 4;
            column-gap: 10px;
        }

        .immo-filter__object-type {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
            position: relative;
            gap: 10px;
            font-family: @font-bold;
            margin-bottom: 10px;
            min-height: 60px;
            display: inline-flex; // Needs to be inline for FF, see https://stackoverflow.com/a/73280825
            //align-items: center;

            li {
                font-family: @font-default;
            }

            &:before {
                content: "";
                width: 60px;
                height: 60px;
                display: block;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: auto 90%;
            }

            &--bueroflaechen:before {background-image: url(../../images/icons/immo/buero.png);}
            &--geschaeftslokale:before {background-image: url(../../images/icons/immo/geschaeft.png);}
            &--grundstuecke:before {background-image: url(../../images/icons/immo/grund.png);}
            &--haeuser:before {background-image: url(../../images/icons/immo/haeuser.png);}
            &--lagergewerbeimmobilien:before {background-image: url(../../images/icons/immo/gewerbe.png);}
            &--wohungen:before {background-image: url(../../images/icons/immo/wohnung.png);}
            &--sonstige:before {background-image: url(../../images/icons/immo/sonstiges_schild.png);}
        }
    }

    .immo-filter__list--state {
        @media @screen-sm-min {
            column-count: 3;
            column-gap: 10px;
        }

        @media @screen-lg-min {
            column-count: 5;
            column-gap: 10px;
        }
    }

    .no-result {
        list-style: none;
        padding-left: 20px;
    }

    .immo-filter__checkbox {
        margin-right: 5px;
    }

    .searchagent {
        background: white;
        padding: 10px 0;
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: 102;
        transform: translate(-50%, -50%);
        display: none;

        @media @screen-sm-min {
            min-width: 480px;
        }
    }

    .immo-filter--searchagent-opened .searchagent {
        display: block;
    }

    .immo-filter--searchagent-opened:after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.8);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 101;
    }

    .searchagent__label {
        width: 100px;
        display: inline-block;
    }

    .searchagent__input {
        width: 400px;
        margin-bottom: 10px;
    }

    .searchagent__buttons {
        text-align: right;
        margin-top: 10px;
    }

    .searchagent__button {
        margin-left: 10px;
    }

    .searchagent__inner .ic-loading {
        animation: spin 1s linear infinite;
        font-size: 28px;
        margin: 0 0 10px;
    }

    .message {
        font-size: 1.5em;
        text-align: center;
        padding: 15px;
        width: 100%;

        &--true {
            background: @success-background;
        }

        &--false {
            background: @error-background;
        }

        &--disabled {
            display: none!important;
        }
    }

    .message-searchagent-disabled {
        display: none;
    }

    .js-searchagent-disabled .message-searchagent-disabled {
        display: block;
    }

    .message__close {
        float: right;
        background: transparent;
        border: none;
        color: @black-70;

        &:hover,
        &:focus {
            color: @oebb-red;
        }
    }

    .searchagent__data-conditions, .searchagent__explanatory-video {
        &:extend(.row p a all);
        display: flex;
        align-items: center;
    }

    .searchagent__explanatory-video {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
    }

}
